import classNames from "classnames";
import "./MediaSection.scss";
import { MediaSectionProps } from "./MediaSection.props";
import DesktopMediaSection from "./DesktopMediaSection/DesktopMediaSection";
import { MobileMediaSection } from "./MobileMediaSection";

const MediaSection = ({
  className = "",
  imageSource = "",
  videoSource = "",
  transformX = "0",
  left = "0",
  bgImageStyle = {},
  forceDesktopView = false,
}: MediaSectionProps) => {
  return (
    <>
      <DesktopMediaSection
        className={classNames(`media-section ${className}`, {
          "force-show": forceDesktopView,
        })}
        imageSource={imageSource}
        videoSource={videoSource}
        transformX={transformX}
        left={left}
        bgImageStyle={bgImageStyle}
      />
      <MobileMediaSection
        className={classNames(`media-section ${className}`, {
          "force-hide": forceDesktopView,
        })}
        imageSource={imageSource}
        videoSource={videoSource}
      />
    </>
  );
};

export default MediaSection;
