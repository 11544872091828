import { Link } from "react-router-dom";
import { LinkButtonProps } from "./LinkButton.props";
import "./LinkButton.scss";

const LinkButton = ({
  children,
  className = "",
  ...restProps
}: LinkButtonProps) => {
  return (
    <Link {...restProps} className={`link-button ${className}`}>
      {children}
    </Link>
  );
};

export default LinkButton;
