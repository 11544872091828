import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  MediaSection,
  TopSection,
  ProjectAboutSection,
  LoadingWrapper,
  TitleTextSection,
  ProjectCardsSection,
  ProjectCardsRowsSection,
  ProjectsOptionsList,
} from "../../components";
import { ProjectPageProps } from "./ProjectPage.props";
import "./ProjectPage.scss";
import { useDBData } from "../../hooks";
import { LoadStatus } from "../../@types";
import {
  ProjectPageSchema,
  ProjectPreviewListSchema,
  TextPatternSchema,
} from "../../schemas";
import { useRandomProjects } from "./hooks";

const ProjectPage = (props: ProjectPageProps) => {
  const navigate = useNavigate();
  const { projectName } = useParams();
  const [projectData, projectDataStatus] = useDBData<ProjectPageSchema>({
    path: `projects/${projectName}`,
  });
  const [projectsPreviewListData = {}] = useDBData<ProjectPreviewListSchema>({
    path: `projectsPreviewList`,
  });

  const [textSplitPattern, textSplitPatternDBStatus] =
    useDBData<TextPatternSchema>({
      path: "textSplitPattern",
    });

  const filteredProjects = useRandomProjects({
    projectsPreviewListData,
    currentProjectName: projectName as string,
    size: 3,
  });

  useEffect(() => {
    if (projectDataStatus === LoadStatus.SUCCESS && !projectData) {
      navigate("/");
    }
  }, [projectDataStatus, projectData, navigate]);

  return (
    <div className="project-page">
      <div className="project-page-container">
        <LoadingWrapper
          loading={
            projectDataStatus !== LoadStatus.SUCCESS ||
            textSplitPatternDBStatus !== LoadStatus.SUCCESS
          }
        >
          <TopSection
            {...projectData?.topSection}
            lineBreakPattern={textSplitPattern?.lineBreakPattern}
          />
          <MediaSection
            {...projectData?.mediaSection}
            bgImageStyle={{
              objectFit: "cover",
              height: "100%",
              width: "100%",
            }}
          />
          <ProjectAboutSection {...projectData?.aboutSection} />
          <MediaSection
            className="mid-media-section"
            transformX="-50%"
            left="50%"
            {...projectData?.midMediaSection}
          />
          <TitleTextSection {...projectData?.midCardsSection?.heading} />
          <MediaSection
            transformX="-50%"
            left="50%"
            className="mid-media-section"
            imageSource={projectData?.midCardsSection?.headingImage}
          />
          <ProjectCardsSection
            cards={projectData?.midCardsSection?.cards ?? []}
          />
          <TitleTextSection
            {...projectData?.bottomCardsSection?.headingCardsContent?.heading}
          />
          <MediaSection
            transformX="-50%"
            left="50%"
            className="mid-media-section"
            imageSource={
              projectData?.bottomCardsSection?.headingCardsContent?.headingImage
            }
          />
          <ProjectCardsRowsSection
            cards={
              projectData?.bottomCardsSection?.headingCardsContent?.cards ?? []
            }
          />
          <TitleTextSection
            {...projectData?.bottomCardsSection?.mainCardsContent?.heading}
          />
          <MediaSection
            transformX="-50%"
            left="50%"
            className="mid-media-section"
            imageSource={
              projectData?.bottomCardsSection?.mainCardsContent?.headingImage
            }
          />
          <ProjectCardsSection
            cards={
              projectData?.bottomCardsSection?.mainCardsContent?.cards ?? []
            }
          />
          <ProjectsOptionsList projects={filteredProjects.slice(0, 3)} />
        </LoadingWrapper>
      </div>
    </div>
  );
};

export default ProjectPage;
