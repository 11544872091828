import { AnimatePresence } from "framer-motion";
import { v4 as uuidv4 } from "uuid";
import { RollingLink } from "../RollingLink";
import { FooterProps } from "./Footer.props";
import "./Footer.scss";
import { FooterSchema } from "../../schemas";
import { useDBData } from "../../hooks";

const Footer = ({ linkOptions }: FooterProps) => {
  const [footerData] = useDBData<FooterSchema>({ path: "footer" });

  return (
    <footer className="footer">
      {footerData ? (
        <div className="footer-container">
          <div className="footer-content">
            <div className="footer-side-sections">
              <div className="footer-side footer-side-left">
                <span className="footer-title">{footerData.title}</span>
                <a
                  className="footer-email-link footer-anchor"
                  href={`mailto:${footerData.email}`}
                >
                  {footerData.emailText}
                </a>
                <a
                  className="footer-tel-link footer-anchor"
                  href={`tel:${footerData.phone}`}
                >
                  {footerData.phoneText}
                </a>
              </div>
              <div className="footer-side footer-side-right">
                <div className="footer-links">
                  <AnimatePresence>
                    {linkOptions.map((optionProps, linkOptionIndex) => (
                      <RollingLink
                        {...optionProps}
                        className="footer-link"
                        key={`${
                          optionProps.text
                        }-${linkOptionIndex}-${uuidv4()}`}
                      />
                    ))}
                  </AnimatePresence>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <span>{footerData.copyrightText}</span>
            </div>
          </div>
        </div>
      ) : null}
    </footer>
  );
};
export default Footer;
