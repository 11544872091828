import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "react-router-dom";
import "./MenuHamburger.scss";
import { MenuHamburgerProps } from "./MenuHamburger.pops";
import { HamburgerButton } from "./HamburgerButton";
import { ReactComponent as BehanceIcon } from "../../assets/icons/behance.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/icons/linkedin.svg";
import { RollingLink } from "../RollingLink";
import { LinkButton } from "../LinkButton";

const itemVariants = {
  closed: {
    opacity: 0,
  },
  open: { opacity: 1 },
};

const sideVariants = {
  open: {
    transition: {
      staggerChildren: 0.2,
      staggerDirection: 1,
      delayChildren: 0.3,
    },
  },
};

const MenuHamburger = ({
  menuOptions = [],
  socialMenuOptions = {},
  email = "",
  emailText = "",
  cvLink = "",
  className = "",
}: MenuHamburgerProps) => {
  const [open, setOpen] = useState(false);

  const onMenuButtonClick = () => {
    setOpen((prevOpenState) => !prevOpenState);
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [open]);

  return (
    <main className={className}>
      <AnimatePresence>
        {open && (
          <>
            <motion.div
              onClick={() => setOpen(false)}
              className="menu-overlay"
              initial={{ display: "none", opacity: 0 }}
              animate={{
                opacity: 0.7,
                display: "block",
                transition: {
                  ease: [0.19, 1, 0.22, 1],
                  duration: 1,
                },
              }}
              exit={{
                display: "none",
                opacity: 0,
                transition: { duration: 0.3 },
              }}
            />
            <motion.aside
              className="menu-aside"
              initial={{ right: -632 }}
              animate={{
                right: 0,
                transition: {
                  ease: [0.19, 1, 0.22, 1],
                  duration: 0.9,
                },
              }}
              exit={{
                right: -632,
                transition: { duration: 0.3 },
              }}
            >
              <div className="menu-content">
                <motion.div
                  className="menu-container"
                  initial="closed"
                  animate="open"
                  exit="closed"
                  variants={sideVariants}
                >
                  <div className="menu-column menu-column-options">
                    <span className="menu-column-header">menu</span>
                    {menuOptions.map(({ text, link }, menuOptionIndex) => (
                      <RollingLink
                        href={link}
                        text={text}
                        key={`${text}-${menuOptionIndex}-${uuidv4()}`}
                        variants={itemVariants}
                      />
                    ))}
                  </div>
                </motion.div>
                <div className="email-link">
                  <div>Get in touch</div>
                  <a href={`mailto:${email}`}>{emailText}</a>
                </div>
                <div className="bottom-row">
                  <div className="social-links">
                    {socialMenuOptions.behance && (
                      <Link
                        to={socialMenuOptions.behance.link}
                        target="_blank"
                        className="social-link"
                      >
                        <BehanceIcon />
                      </Link>
                    )}
                    {socialMenuOptions.linkedIn && (
                      <Link
                        to={socialMenuOptions.linkedIn.link}
                        target="_blank"
                        className="social-link"
                      >
                        <LinkedInIcon />
                      </Link>
                    )}
                  </div>
                  <LinkButton to={cvLink} download target="_blank">
                    Download CV
                  </LinkButton>
                </div>
              </div>
            </motion.aside>
          </>
        )}
      </AnimatePresence>
      <span className="menu-text-button" onClick={onMenuButtonClick}>
        menu
      </span>
      <HamburgerButton
        className="menu-button"
        open={open}
        onClick={onMenuButtonClick}
      />
    </main>
  );
};

export default MenuHamburger;
