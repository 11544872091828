import { v4 as uuidv4 } from "uuid";
import "./ProjectCardsSection.scss";
import { ProjectCardsSectionProps } from "./ProjectCardsSection.props";
import ProjectCard from "./ProjectCard/ProjectCard";

const ProjectCardsSection = ({
  className = "",
  cards = [],
}: ProjectCardsSectionProps) => {
  return (
    <section className={`project-cards-section ${className}`}>
      <div className="project-cards-section-container">
        <div className="project-cards-section-grid">
          <div className="project-cards-section-grid-column mobile-column">
            {cards.map((card, cardIndex) => (
              <ProjectCard key={`${cardIndex}-${uuidv4()}`} {...card} />
            ))}
          </div>
          <div className="project-cards-section-grid-column left-column">
            {cards
              .filter((_, index) => !(index % 2))
              .map((card, cardIndex) => (
                <ProjectCard key={`${cardIndex}-${uuidv4()}`} {...card} />
              ))}
          </div>
          <div className="project-cards-section-grid-column right-column">
            {cards
              .filter((_, index) => index % 2)
              .map((card, cardIndex) => (
                <ProjectCard key={`${cardIndex}-${uuidv4()}`} {...card} />
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectCardsSection;
