import { VerticalAnimatedSection } from "./VerticalAnimatedSection";
import { VerticalAnimatedSectionsProps } from "./VerticalAnimatedSections.props";
import "./VerticalAnimatedSections.scss";

const VerticalAnimatedSections = ({
  className = "",
  sections = [],
  handleColorsChange,
}: VerticalAnimatedSectionsProps) => {
  return (
    <section className={`vertical-animated-sections ${className}`}>
      <div className="vertical-animated-sections-wrapper">
        <div className="vertical-animated-sections-container">
          {sections.map((section, rowIndex) => (
            <VerticalAnimatedSection
              key={`vertical-animated-section-card-${section.title}-${rowIndex}`}
              {...section}
              order={rowIndex % 2 ? "right" : "left"}
              handleColorsChange={handleColorsChange}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default VerticalAnimatedSections;
