import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import "./VerticalAnimatedSection.scss";
import { VerticalAnimatedSectionProps } from "./VerticalAnimatedSection.props";
import { Parallax } from "../../Parallax";
import { Link } from "react-router-dom";

const VerticalAnimatedSection = ({
  className = "",
  title,
  text,
  subText,
  previewImageSource,
  order,
  projectName,
  backgroundColor = "",
  color = "",
  handleColorsChange,
}: VerticalAnimatedSectionProps) => {
  const [percentage, setPercentage] = useState(0);

  const { ref: viewRef, inView } = useInView({
    threshold: 0.7,
    delay: 300,
  });

  useEffect(() => {
    inView && handleColorsChange(color, backgroundColor);
  }, [inView, color, backgroundColor, handleColorsChange]);

  return (
    <section
      className={`vertical-animated-section ${className}`}
      data-order={order}
    >
      <div
        className="text-content"
        style={{
          transform: `translate3d(0px, ${12 * percentage * 3 * -1}px, 0px)`,
        }}
      >
        <div className="title">{title}</div>
        <div className="text">{text}</div>
        <div className="subText">{subText}</div>
      </div>
      <div className="media-content">
        <Link to={`/projects/${projectName}`}>
          <Parallax
            bgImage={previewImageSource}
            strength={100}
            renderLayer={(percentage) => setPercentage(() => percentage)}
            bgImageStyle={{
              objectFit: "contain",
              width: "100%",
              heading: "auto",
            }}
          >
            <div
              className="project-card-image"
              style={{ aspectRatio: "1052 / 1052" }}
              ref={viewRef}
            ></div>
          </Parallax>
        </Link>
      </div>
    </section>
  );
};

export default VerticalAnimatedSection;
