import { MobileMediaSectionProps } from "./MobileMediaSection.props";
import "./MobileMediaSection.scss";

const MobileMediaSection = ({
  className = "",
  imageSource = "",
  videoSource = "",
}: MobileMediaSectionProps) => {
  return !imageSource && !videoSource ? null : (
    <section className={`mobile-media-section ${className}`}>
      {imageSource ? (
        <img src={imageSource} alt="mobile-media-section" />
      ) : (
        <video src={videoSource} />
      )}
    </section>
  );
};

export default MobileMediaSection;
