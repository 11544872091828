import { v4 as uuidv4 } from "uuid";
import Carousel from "nuka-carousel";
import "./ProjectsOptionsList.scss";
import { ProjectsOptionsListProps } from "./ProjectsOptionsList.props";
import { ProjectsOptionsListItem } from "./ProjectsOptionsListItem";
import { LinkButton } from "../LinkButton";

const ProjectsOptionsList = ({
  projects = [],
  className = "",
}: ProjectsOptionsListProps) => {
  return projects.length ? (
    <div className={`projects-options-list ${className}`}>
      <div className="projects-options-list-wrapper">
        <div className="projects-options-list-container">
          <div className="projects-options-list-heading">
            <span className="title"> More Projects</span>
            <LinkButton className="all-projects-link desktop" to="/projects">
              View All Projects
            </LinkButton>
          </div>
          <div className="projects-options-list-projects desktop">
            {projects.map((project, projectIndex) => (
              <ProjectsOptionsListItem
                key={`${projectIndex}-${uuidv4()}`}
                {...project}
              />
            ))}
          </div>
          <div className="projects-options-list-projects mobile">
            <Carousel
              cellSpacing={15}
              cellAlign="right"
              style={{ overflow: "visible" }}
              defaultControlsConfig={{
                nextButtonStyle: { display: "none" },
                prevButtonStyle: { display: "none" },
              }}
            >
              {projects.map((project, projectIndex) => (
                <ProjectsOptionsListItem
                  key={`${projectIndex}-${uuidv4()}`}
                  {...project}
                />
              ))}
            </Carousel>
          </div>
          <LinkButton className="all-projects-link mobile" to="/projects">
            View All Projects
          </LinkButton>
        </div>
      </div>
    </div>
  ) : null;
};

export default ProjectsOptionsList;
