import { LoadingWrapperProps } from "./LoadingWrapper.props";
import "./LoadingWrapper.scss";

const LoadingWrapper = ({
  className = "",
  loading = false,
  children,
}: LoadingWrapperProps) => {
  return loading ? (
    <div className={`loading-wrapper ${className}`}></div>
  ) : (
    <>{children}</>
  );
};

export default LoadingWrapper;
