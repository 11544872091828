import { useMemo } from "react";
import { SkillSectionProps } from "../../SkillSection";

interface UseSelectedSectionProps {
  sectionIndex: number;

  sections: Array<Omit<SkillSectionProps, "className">>;
}

const useSelectedSection = ({
  sectionIndex,
  sections = [],
}: UseSelectedSectionProps) => {
  return useMemo(() => {
    return sections[sectionIndex] || {};
  }, [sectionIndex, sections]);
};

export default useSelectedSection;
