import { forwardRef } from "react";
import classNames from "classnames";
import { TopSectionProps } from "./TopSection.props";
import "./TopSection.scss";
import { ReactComponent as ScrollDownIcon } from "../../assets/icons/scroll-down.svg";
import { useTextSplit } from "../../hooks";

const TopSection = forwardRef<HTMLElement, TopSectionProps>(
  (
    {
      className = "",
      title = "",
      text = "",
      subTitle = "",
      lineBreakPattern = "",
    }: TopSectionProps,
    ref
  ) => {
    return (
      <section className={`top-section ${className}`} ref={ref}>
        <div className="top-section-container">
          <div className="top-section-sub-title">{subTitle}</div>
          <div className="top-section-title">
            {useTextSplit({ text: title, pattern: lineBreakPattern })}
          </div>
          <div
            className={classNames("top-section-content", {
              "space-between": text,
              end: !text,
            })}
          >
            {text && <span className="top-section-description">{text}</span>}
            <ScrollDownIcon className="scroll-down-icon" />
          </div>
        </div>
      </section>
    );
  }
);

export default TopSection;
