import { useState } from "react";
import { Link } from "react-router-dom";
import { Parallax } from "../../Parallax";
import { ProjectsGalleryItemProps } from "./ProjectsGalleryItem.props";
import "./ProjectsGalleryItem.scss";

const ProjectsGalleryItem = ({
  imageSource,
  title,
  subTitle,
  text,
  projectName,
}: ProjectsGalleryItemProps) => {
  const [percentage, setPercentage] = useState(0);

  return (
    <div className="projects-gallery-item">
      <div className="projects-gallery-item-container">
        <Link
          to={`/projects/${projectName}`}
          className="projects-gallery-item-link"
        >
          <Parallax
            bgImage={imageSource}
            strength={100}
            renderLayer={(percentage) => setPercentage(() => percentage)}
            bgImageStyle={{
              width: "100%",
              objectFit: "contain",
              objectPosition: "top",
            }}
          >
            <div style={{ aspectRatio: "678 / 800" }}></div>
          </Parallax>
          <div
            className="projects-gallery-item-content"
            style={{
              transform: `translate3d(0px, ${12 * percentage * 3}px, 0px)`,
            }}
          >
            <span className="projects-gallery-item-title">{title}</span>
            <p className="projects-gallery-item-text">{text}</p>
            <span className="projects-gallery-item-subtitle">{subTitle}</span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default ProjectsGalleryItem;
