import "./SkillsList.scss";
import { SkillsListProps } from "./SkillsList.props";

const SkillsList = ({ className = "", skills = [] }: SkillsListProps) => {
  return (
    <div className={`skills-list ${className}`}>
      {skills.map(({ title: skillTitle, text }) => (
        <div key={`skill-section-skill-${skillTitle}`} className="skill-option">
          <div className="skill-title">{skillTitle}</div>
          <div className="skill-text">{text}</div>
        </div>
      ))}
    </div>
  );
};

export default SkillsList;
