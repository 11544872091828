import { ReactNode, useMemo } from "react";

interface UseTextSplitProps {
  text?: string;
  pattern?: string;
}

export const useTextSplit = ({
  text = "",
  pattern = "",
}: UseTextSplitProps): ReactNode => {
  return useMemo(() => {
    if (!pattern) return <span className="text-element">{text}</span>;
    const separatedText = text.split(pattern);
    return separatedText.map((textElement, textElementIndex) =>
      textElementIndex !== separatedText.length - 1 ? (
        <>
          <span className="text-element" key={textElement}>
            {textElement}
          </span>
          <br key={`${textElement}-br`} />
        </>
      ) : (
        <span className="text-element" key={textElement}>
          {textElement}
        </span>
      )
    );
  }, [text, pattern]);
};

export default useTextSplit;
