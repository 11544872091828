import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import {
  HomePageIntroSection,
  LinkButton,
  MainImageSection,
  TopSection,
  VerticalAnimatedSections,
  AboutSection,
  LoadingWrapper,
} from "../../components";
import { HomePageProps } from "./HomePage.props";
import "./HomePage.scss";
import { HomePageSchema, TextPatternSchema } from "../../schemas";
import { useDBData } from "../../hooks";
import { LoadStatus } from "../../@types";

const INITIAL_COLOR = "black";
const INITIAL_BACKGROUND_COLOR = "white";

const HomePage = (props: HomePageProps) => {
  const [color, setColor] = useState<string>("black");
  const [backgroundColor, setBackgroundColor] = useState<string>("white");

  const { ref: bottomRef, inView: bottomInView } = useInView({
    threshold: 1,
  });

  const { ref: introRef, inView: introInView } = useInView({
    threshold: 1,
  });

  const { ref: topSectionRef, inView: topSectionInView } = useInView({
    threshold: 1,
  });

  const [homePageData, homePageDBStatus] = useDBData<HomePageSchema>({
    path: "homePage",
  });

  const [textSplitPattern, textSplitPatternDBStatus] =
    useDBData<TextPatternSchema>({
      path: "textSplitPattern",
    });

  useEffect(() => {
    (bottomInView || introInView || topSectionInView) &&
      handleColorsChange(INITIAL_COLOR, INITIAL_BACKGROUND_COLOR);
  }, [bottomInView, introInView, topSectionInView]);

  const handleColorsChange = (newColor: string, newBackgroundColor: string) => {
    setColor(() => newColor);
    setBackgroundColor(() => newBackgroundColor);
  };
  return (
    <div
      className="home-page"
      style={{
        color,
        backgroundColor,
      }}
    >
      <div className="home-page-container">
        <LoadingWrapper
          loading={
            !homePageData ||
            homePageDBStatus !== LoadStatus.SUCCESS ||
            textSplitPatternDBStatus !== LoadStatus.SUCCESS
          }
        >
          <TopSection
            className="home-page-top-section"
            {...homePageData?.topSection}
            ref={topSectionRef}
            lineBreakPattern={textSplitPattern?.lineBreakPattern}
          />
          <MainImageSection
            alt={homePageData?.mainImageSection.alt}
            src={homePageData?.mainImageSection.imageSrc}
          />
          <HomePageIntroSection
            {...homePageData?.introSection}
            ref={introRef}
          />
          <VerticalAnimatedSections
            backgroundColor={backgroundColor}
            sections={homePageData?.verticalAnimatedSections.sections || []}
            handleColorsChange={handleColorsChange}
          />
          <section
            className="home-page-all-project-section"
            style={{ backgroundColor }}
          >
            <LinkButton className="link" to="/projects">
              All Projects
            </LinkButton>
          </section>
          <AboutSection ref={bottomRef} {...homePageData?.aboutSection} />
        </LoadingWrapper>
      </div>
    </div>
  );
};

export default HomePage;
