import { v4 as uuidv4 } from "uuid";
import "./ProjectCardsRowsSection.scss";
import { ProjectCardsRowsSectionProps } from "./ProjectCardsRowsSection.props";
import { OrientationOption, ProjectCardRow } from "./ProjectCardRow";

const ProjectCardsRowsSection = ({
  className = "",
  startOrientation = OrientationOption.LEFT,
  cards = [],
}: ProjectCardsRowsSectionProps) => {
  const getOppositeOrientation = (
    orientation: OrientationOption
  ): OrientationOption =>
    orientation === OrientationOption.LEFT
      ? OrientationOption.RIGHT
      : OrientationOption.LEFT;

  const getOrientation = (rowIndex: number): OrientationOption => {
    if (!(rowIndex % 2)) {
      return startOrientation;
    } else {
      return getOppositeOrientation(startOrientation);
    }
  };

  return cards.length ? (
    <section className={`project-cards-rows-section ${className}`}>
      <div className="project-cards-rows-section-container">
        {cards.map((card, cardIndex) => (
          <ProjectCardRow
            key={`${cardIndex}-${uuidv4()}`}
            {...card}
            orientation={getOrientation(cardIndex)}
          />
        ))}
      </div>
    </section>
  ) : null;
};

export default ProjectCardsRowsSection;
