import "./ProjectsOptionsListItem.scss";
import { ProjectsOptionsListItemProps } from "./ProjectsOptionsListItem.props";
import { LinkButton } from "../../LinkButton";

const ProjectsOptionsListItem = ({
  className = "",
  projectImageSrc = "",
  title = "",
  text = "",
  projectName = "",
}: ProjectsOptionsListItemProps) => {
  return (
    <div className={`projects-options-list-item ${className}`}>
      <div className="projects-options-list-item-container">
        <div className="projects-options-list-item-image-container">
          <img src={projectImageSrc} alt={`${projectName} project preview`} />
        </div>
        <span className="title">{title}</span>
        <span className="text">{text}</span>
        <LinkButton className="link" to={`/projects/${projectName}`}>
          View Project
        </LinkButton>
      </div>
    </div>
  );
};

export default ProjectsOptionsListItem;
