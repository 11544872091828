import "./FloatingCardsSection.scss";
import { FloatingCardsSectionProps } from "./FloatingCardsSection.props";
import FloatingCard from "./FloatingCard/FloatingCard";

const FloatingCardsSection = ({
  className = "",
  title,
  cards = [],
}: FloatingCardsSectionProps) => {
  return (
    <section className={`floating-cards-section ${className}`}>
      <div className="title">{title}</div>
      <div className="cards">
        {cards.map((card, cardIndex) => (
          <FloatingCard {...card} key={`floating-card-${title}-${cardIndex}`} />
        ))}
      </div>
    </section>
  );
};

export default FloatingCardsSection;
