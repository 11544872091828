import { RollingLinkProps } from "./RollingLink.props";
import "./RollingLink.scss";
import { motion } from "framer-motion";

const RollingLink = ({
  text,
  className = "",
  ...restProps
}: RollingLinkProps) => {
  return (
    <motion.a {...restProps} className={`rolling-link ${className}`}>
      <em>
        <span data-text={text}>{text}</span>
      </em>
    </motion.a>
  );
};

export default RollingLink;
