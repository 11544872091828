import { Route, Routes, Navigate } from "react-router-dom";
import useScreenOrientation from "use-screen-orientation";
import "./App.scss";
import "./fonts/ArgentumSans-Black.ttf";
import "./fonts/ArgentumSans-BlackItalic.ttf";
import "./fonts/ArgentumSans-Bold.ttf";
import "./fonts/ArgentumSans-BoldItalic.ttf";
import "./fonts/ArgentumSans-ExtraBold.ttf";
import "./fonts/ArgentumSans-ExtraBoldItalic.ttf";
import "./fonts/ArgentumSans-ExtraLight.ttf";
import "./fonts/ArgentumSans-ExtraLightItalic.ttf";
import "./fonts/ArgentumSans-Italic.ttf";
import "./fonts/ArgentumSans-Light.ttf";
import "./fonts/ArgentumSans-LightItalic.ttf";
import "./fonts/ArgentumSans-Medium.ttf";
import "./fonts/ArgentumSans-MediumItalic.ttf";
import "./fonts/ArgentumSans-Regular.ttf";
import "./fonts/ArgentumSans-SemiBold.ttf";
import "./fonts/ArgentumSans-SemiBoldItalic.ttf";
import "./fonts/ArgentumSans-Thin.ttf";
import "./fonts/ArgentumSans-ThinItalic.ttf";
import { TopNavbar, Footer, FooterLinkOption, ScrollToTop } from "./components";
import { MENU_OPTIONS } from "./constants";
import { AboutPage, HomePage, ProjectPage, ProjectsPage } from "./containers";
import { ReactComponent as RotationIcon } from "./assets/icons/rotation.svg";

const FOOTER_LINK_OPTIONS: Array<FooterLinkOption> = [
  { href: "/", text: "Home" },
  { href: "/projects", text: "Projects" },
  { href: "/about", text: "About" },
];

function App() {
  const [orientation] = useScreenOrientation();

  if (process.env.NODE_ENV === "production") {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }

  return (
    <div className="App">
      {orientation === "portrait" ? (
        <>
          <ScrollToTop />
          <TopNavbar menuOptions={MENU_OPTIONS} />
          <div className="page-content">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/projects" element={<ProjectsPage />} />
              <Route path="/projects/:projectName" element={<ProjectPage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
          <Footer linkOptions={FOOTER_LINK_OPTIONS} />
        </>
      ) : (
        <div className="landscape-warning">
          <RotationIcon className="icon" />
          <div className="title">Please rotate you device</div>
          <div className="text">
            This page is best viewed in portrait orientation
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
