import { filter, sampleSize } from "lodash";
import { useMemo } from "react";
import { ProjectPreviewListSchema } from "../../../schemas";

interface UseRandomProjectsProps {
  projectsPreviewListData: ProjectPreviewListSchema;
  currentProjectName: string;
  size: number;
}

const useRandomProjects = ({
  projectsPreviewListData,
  currentProjectName,
  size,
}: UseRandomProjectsProps) => {
  return useMemo(() => {
    return sampleSize(
      filter(
        Object.entries(projectsPreviewListData).map(
          ([projectName, project]) => ({ projectName, ...project })
        ),
        ({ projectName }) => projectName !== currentProjectName
      ),
      size
    );
  }, [projectsPreviewListData, currentProjectName, size]);
};

export default useRandomProjects;
