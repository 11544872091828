import "./FloatingCard.scss";
import { FloatingCardProps } from "./FloatingCard.props";

const FloatingCard = ({
  className = "",
  imageSrc,
  alt = "Floating Card",
}: FloatingCardProps) => {
  return (
    <div className={`floating-card ${className}`}>
      <div className="image-container">
        <img src={imageSrc} alt={alt} />
      </div>
    </div>
  );
};

export default FloatingCard;
