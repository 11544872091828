import { TitleTextSectionProps } from "./TitleTextSection.props";
import "./TitleTextSection.scss";

const TitleTextSection = ({
  className = "",
  title = "",
  text = "",
}: TitleTextSectionProps) => {
  return !title && !text ? null : (
    <section className={`title-text-section ${className}`}>
      <div className="title-text-section-container">
        <div className="title-text-section-content">
          <span className="title">{title}</span>
          <p className="text">{text}</p>
        </div>
      </div>
    </section>
  );
};

export default TitleTextSection;
