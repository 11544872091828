import "./SkillsSection.scss";
import { SkillsSectionProps } from "./SkillsSection.props";
import { SkillsSectionMobile } from "./SkillsSectionMobile";
import { SkillsSectionDesktop } from "./SkillsSectionDesktop";

const SkillsSection = ({
  className = "",
  skillsOptions = [],
}: SkillsSectionProps) => {
  return (
    <>
      <SkillsSectionMobile
        className={className}
        skillsOptions={skillsOptions}
      />
      <SkillsSectionDesktop
        className={className}
        skillsOptions={skillsOptions}
      />
    </>
  );
};

export default SkillsSection;
