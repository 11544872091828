import classNames from "classnames";
import "./HamburgerButton.scss";
import { HamburgerButtonProps } from "./HamburgerButton.pops";

const HamburgerButton = ({
  open = false,
  onClick,
  className = "",
}: HamburgerButtonProps) => {
  return (
    <div
      className={classNames(`hamburger-button ${className}`, { open })}
      onClick={onClick}
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default HamburgerButton;
