import "./SkillsSectionDesktop.scss";
import { SkillsSectionDesktopProps } from "./SkillsSectionDesktop.props";
import { SkillSection } from "../SkillSection";

const SkillsSectionDesktop = ({
  className = "",
  skillsOptions = [],
}: SkillsSectionDesktopProps) => {
  return (
    <section className={`skills-section-desktop ${className}`}>
      {skillsOptions.map((skillsOption) => (
        <SkillSection key={skillsOption.title} {...skillsOption} />
      ))}
    </section>
  );
};

export default SkillsSectionDesktop;
