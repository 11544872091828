import { useState } from "react";
import classNames from "classnames";
import "./SkillsSectionMobile.scss";
import { SkillsSectionMobileProps } from "./SkillsSectionMobile.props";
import { useSelectedSection } from "./hooks";
import { SkillsList } from "../SkillsList";

const SkillsSectionMobile = ({
  className = "",
  skillsOptions = [],
}: SkillsSectionMobileProps) => {
  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0);

  const selectedSection = useSelectedSection({
    sectionIndex: selectedSectionIndex,
    sections: skillsOptions,
  });

  return (
    <section className={`skills-section-mobile ${className}`}>
      <div className="tabs">
        {skillsOptions.map(({ title }, skillOptionIndex) => (
          <div
            key={title}
            className={classNames("tab", {
              selected: selectedSectionIndex === skillOptionIndex,
            })}
            onClick={() => setSelectedSectionIndex(skillOptionIndex)}
          >
            {title}
          </div>
        ))}
      </div>
      <SkillsList skills={selectedSection.skills} />
    </section>
  );
};

export default SkillsSectionMobile;
