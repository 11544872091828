import "./ProjectCardRow.scss";
import { ProjectCardRowProps, OrientationOption } from "./ProjectCardRow.props";
import { Parallax } from "../../Parallax";

const ProjectCardRow = ({
  className = "",
  imageSrc = "",
  text = "",
  orientation = OrientationOption.LEFT,
}: ProjectCardRowProps) => {
  return (
    <div className={`project-card-row ${className}`}>
      <div className="project-card-row-container" data-order={orientation}>
        <div className="project-card-row-image row-element">
          <Parallax
            bgImage={imageSrc}
            strength={100}
            bgImageStyle={{
              objectFit: "contain",
              width: "100%",
              heading: "auto",
            }}
          >
            <div
              className="project-card-image"
              style={{ aspectRatio: "678 / 678" }}
            ></div>
          </Parallax>
        </div>
        <div className="project-card-row-text row-element">
          <p className="text">{text}</p>
        </div>
      </div>
    </div>
  );
};

export default ProjectCardRow;
