import "./AboutPage.scss";
import { AboutPageProps } from "./AboutPage.props";
import { useDBData } from "../../hooks";
import {
  FloatingCardsSection,
  LoadingWrapper,
  ProjectCardsRowsSection,
  SkillsSection,
  TopSection,
} from "../../components";
import { AboutPageSchema } from "../../schemas";
import { LoadStatus } from "../../@types";

const AboutPage = (props: AboutPageProps) => {
  const [aboutPageData, status] = useDBData<AboutPageSchema>({
    path: "aboutPage",
  });

  return (
    <div className="about-page-container">
      <LoadingWrapper loading={aboutPageData && status !== LoadStatus.SUCCESS}>
        <TopSection
          className="about-page-top-section"
          {...aboutPageData?.topSection}
        />
        <ProjectCardsRowsSection
          className="about-me-section"
          cards={[
            {
              imageSrc: aboutPageData?.aboutMeSection.imageSrc,
              text: (
                <>
                  {aboutPageData?.aboutMeSection.paragraphs.map(
                    (paragraph, paragraphIndex) => (
                      <span
                        key={`about-me-section-paragraph-${paragraphIndex}`}
                      >
                        {paragraph}
                      </span>
                    )
                  )}
                </>
              ),
            },
          ]}
        />
        <SkillsSection
          skillsOptions={aboutPageData?.skillsSection.skillsOptions || []}
          {...aboutPageData?.skillsSection}
        />
        <FloatingCardsSection {...aboutPageData?.featuredClientsSection} />
      </LoadingWrapper>
    </div>
  );
};

export default AboutPage;
