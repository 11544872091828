import "./ProjectAboutSection.scss";
import { ProjectAboutSectionProps } from "./ProjectAboutSection.props";
import { LinkButton } from "../LinkButton";

const ProjectAboutSection = ({
  className = "",
  projectLink = "",
  text = "",
  title = "",
}: ProjectAboutSectionProps) => {
  return (
    <section className={`project-about-section ${className}`}>
      <div className="project-about-section-container">
        <div className="project-about-section-grid">
          <div className="project-about-section-grid-left">
            <span className="title">{title}</span>
          </div>
          <div className="project-about-section-grid-right">
            <p className="text">{text}</p>
            {projectLink ? (
              <LinkButton to={projectLink} target="_blank">
                View Site
              </LinkButton>
            ) : (
              <div className="coming-soon-text">Coming Soon</div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectAboutSection;
