import { v4 as uuidv4 } from "uuid";
import { ProjectsGalleryProps } from "./ProjectsGallery.props";
import "./ProjectsGallery.scss";
import { ProjectsGalleryItem } from "./ProjectsGalleryItem";

const ProjectsGallery = ({
  projects,
  className = "",
  ...restProps
}: ProjectsGalleryProps) => {
  return (
    <div className={`projects-gallery ${className}`} {...restProps}>
      <div className="projects-gallery-container">
        <div className="projects-gallery-grid">
          <div className="projects-gallery-grid-column mobile-column">
            {projects.map((project, projectIndex) => (
              <ProjectsGalleryItem
                {...project}
                key={`${projectIndex}-${uuidv4()}`}
              />
            ))}
          </div>
          <div className="projects-gallery-grid-column left-column">
            {projects
              .filter((_, index) => !(index % 2))
              .map((project, projectIndex) => (
                <ProjectsGalleryItem
                  {...project}
                  key={`${projectIndex}-${uuidv4()}`}
                />
              ))}
          </div>
          <div className="projects-gallery-grid-column right-column">
            {projects
              .filter((_, index) => index % 2)
              .map((project, projectIndex) => (
                <ProjectsGalleryItem
                  {...project}
                  key={`${projectIndex}-${uuidv4()}`}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsGallery;
