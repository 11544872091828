import "./SkillSection.scss";
import { SkillSectionProps } from "./SkillSection.props";
import { SkillsList } from "../SkillsList";

const SkillSection = ({
  className = "",
  title,
  skills = [],
}: SkillSectionProps) => {
  return (
    <div className={`skill-section ${className}`}>
      <div className="title">{title}</div>
      <SkillsList skills={skills} />
    </div>
  );
};

export default SkillSection;
