import { ProjectsGallery, TopSection, LoadingWrapper } from "../../components";
import { ProjectsPageProps } from "./ProjectsPage.props";
import "./ProjectsPage.scss";
import { useDBData } from "../../hooks";
import { ProjectsPageSchema } from "../../schemas";
import { LoadStatus } from "../../@types";

const ProjectsPage = ({ ...restProps }: ProjectsPageProps) => {
  const [projectsPageData, status] = useDBData<ProjectsPageSchema>({
    path: "projectsPage",
  });
  return (
    <div className="projects-page" {...restProps}>
      <div className="projects-page-container">
        <LoadingWrapper
          loading={projectsPageData && status !== LoadStatus.SUCCESS}
        >
          <TopSection
            subTitle={projectsPageData?.topSection.subTitle || ""}
            title={projectsPageData?.topSection.title || ""}
            className="projects-page-top-section"
          />
          <ProjectsGallery projects={projectsPageData?.projects || []} />
        </LoadingWrapper>
      </div>
    </div>
  );
};

export default ProjectsPage;
