import { forwardRef } from "react";
import { HomePageIntroSectionProps } from "./HomePageIntroSection.props";
import "./HomePageIntroSection.scss";

const HomePageIntroSection = forwardRef<HTMLElement, HomePageIntroSectionProps>(
  (
    { className = "", title = "", text = "" }: HomePageIntroSectionProps,
    ref
  ) => {
    return (
      <section className={`home-page-intro-section ${className}`} ref={ref}>
        <div className="home-page-intro-section-container">
          <div className="home-page-intro-section-content">
            <div className="home-page-intro-section-title">{title}</div>
            <p className="home-page-intro-section-description">{text}</p>
          </div>
        </div>
      </section>
    );
  }
);

export default HomePageIntroSection;
