import { ReactElement } from "react";

export enum OrientationOption {
  LEFT = "left",
  RIGHT = "right",
}

export interface ProjectCardRowProps {
  className?: string;
  imageSrc?: string;
  text?: string | ReactElement;
  orientation: OrientationOption;
}
