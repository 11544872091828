import { Parallax } from "../../Parallax";
import { DesktopMediaSectionProps } from "./DesktopMediaSection.props";
import "./DesktopMediaSection.scss";

const DesktopMediaSection = ({
  className = "",
  imageSource = "",
  videoSource = "",
  transformX = "0",
  left = "0",
  bgImageStyle = {},
}: DesktopMediaSectionProps) => {
  return !imageSource && !videoSource ? null : (
    <section className={`desktop-media-section ${className}`}>
      {imageSource ? (
        <Parallax
          bgImage={imageSource}
          strength={100}
          transformX={transformX}
          left={left}
          bgImageStyle={bgImageStyle}
        >
          <div className="content-inner"></div>
        </Parallax>
      ) : (
        <Parallax
          bgVideo={videoSource}
          strength={100}
          transformX={transformX}
          left={left}
        >
          <div className="content-inner"></div>
        </Parallax>
      )}
    </section>
  );
};

export default DesktopMediaSection;
