import { Link } from "react-router-dom";
import { useDBData } from "../../hooks";
import { NavbarSchema } from "../../schemas";
import { MenuHamburger } from "../MenuHamburger";
import { TopNavbarProps } from "./TopNavbar.props";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import "./TopNavbar.scss";

const TopNavbar = ({ menuOptions }: TopNavbarProps) => {
  const [navbarData] = useDBData<NavbarSchema>({ path: "navbar" });

  return (
    <nav className="top-navbar">
      <div className="top-navbar-container">
        <Link className="home-link" to="">
          <LogoIcon className="logo-icon" />
        </Link>
        <MenuHamburger
          className="top-navbar-menu"
          menuOptions={menuOptions}
          {...navbarData}
        />
      </div>
    </nav>
  );
};

export default TopNavbar;
