import { forwardRef } from "react";
import { Link } from "react-router-dom";
import "./AboutSection.scss";
import { ReactComponent as BehanceIcon } from "../../assets/icons/behance.svg";
import { ReactComponent as LinkedInIcon } from "../../assets/icons/linkedin.svg";
import { AboutSectionProps } from "./AboutSection.props";
import { LinkButton } from "../LinkButton";

const AboutSection = forwardRef<HTMLElement, AboutSectionProps>(
  (
    {
      className = "",
      title = "",
      text = "",
      linkedInLink = "",
      behanceLink = "",
      cvLink = "",
    }: AboutSectionProps,
    ref
  ) => {
    return (
      <section ref={ref} className={`about-section ${className}`}>
        <div className="about-section-wrapper">
          <div className="about-section-container">
            <div className="about-section-content">
              <span className="about-section-title">{title}</span>
              <p className="about-section-text">{text}</p>
              <div className="about-section-social mobile">
                <Link to={behanceLink} target="_blank" className="social-link">
                  <BehanceIcon />
                </Link>
                <Link to={linkedInLink} target="_blank" className="social-link">
                  <LinkedInIcon />
                </Link>
              </div>
              <div className="link-buttons">
                <LinkButton className="link-button" to="/about">
                  About
                </LinkButton>
                <LinkButton
                  className="link-button"
                  to={cvLink}
                  download
                  target="_blank"
                >
                  CV
                </LinkButton>
              </div>
            </div>
            <div className="about-section-social desktop">
              <Link to={behanceLink} target="_blank" className="social-link">
                <BehanceIcon />
              </Link>
              <Link to={linkedInLink} target="_blank" className="social-link">
                <LinkedInIcon />
              </Link>
            </div>
          </div>
        </div>
      </section>
    );
  }
);

export default AboutSection;
