import { onValue, ref } from "firebase/database";
import { useEffect, useState } from "react";
import { LoadStatus } from "../@types";
import { db } from "../utils/firebase";

export const useDBData = <T extends object>({
  path,
}: {
  path: string;
}): [T | undefined, LoadStatus] => {
  const [data, setData] = useState<T>();
  const [status, setStatus] = useState<LoadStatus>(LoadStatus.INDETERMINATE);

  useEffect(() => {
    setData(undefined);
    setStatus(LoadStatus.LOADING);
    const query = ref(db, path);
    return onValue(query, (snapshot) => {
      const data = snapshot.val();

      if (snapshot.exists()) {
        setData((prevData) => {
          setStatus(() => LoadStatus.SUCCESS);
          return { ...prevData, ...data };
        });
      } else {
        setStatus(() => LoadStatus.SUCCESS);
      }
    });
  }, [path]);

  return [data, status];
};
