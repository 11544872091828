export const MENU_OPTIONS = [
  {
    link: "/",
    text: "Home",
  },
  {
    link: "/projects",
    text: "Projects",
  },
  {
    link: "/about",
    text: "About",
  },
];
