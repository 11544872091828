import { useState } from "react";
import "./ProjectCard.scss";
import { ProjectCardProps } from "./ProjectCard.props";
import { Parallax } from "../../Parallax";

const ProjectCard = ({
  className = "",
  imageSrc = "",
  text = "",
}: ProjectCardProps) => {
  const [percentage, setPercentage] = useState(0);
  return (
    <div className={`project-card ${className}`}>
      <div className="project-card-container">
        <Parallax
          bgImage={imageSrc}
          strength={100}
          renderLayer={(percentage) => setPercentage(() => percentage)}
          bgImageStyle={{
            width: "100%",
            objectFit: "contain",
            objectPosition: "top",
          }}
        >
          <div
            className="project-card-image"
            style={{ aspectRatio: "678 / 800" }}
          ></div>
        </Parallax>
        {text && (
          <div
            className="project-card-content"
            style={{
              transform: `translate3d(0px, ${12 * percentage * 2}px, 0px)`,
            }}
          >
            <p className="project-card-text">{text}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectCard;
